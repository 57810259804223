import React from "react";
import { Link } from "react-router-dom";

export const MenuMobile = ({ collapseMenu }) => {
  return (
    <ul className="navbar-nav nav-mobile mr-auto container d-flex justify-content-around align-items-start">
      <Link className="nav-link" to="/" onClick={collapseMenu}>
        INÍCIO
      </Link>

      <Link className="nav-link" to="/quem-somos/" onClick={collapseMenu}>
        QUEM SOMOS
      </Link>
      {/*
      <Link className="nav-link" to="/noticias/" onClick={collapseMenu}>
        NOTÍCIAS
      </Link>
  */}
      <Link className="nav-link">REGISTRO DE IMÓVEIS</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="/faq-registro/"
          onClick={collapseMenu}
        >
          FAQ
        </Link>
        <Link
          className="dropdown-item"
          to="/legislacao/"
          onClick={collapseMenu}
        >
          LEGISLAÇÃO
        </Link>
        <Link
          className="dropdown-item"
          to="/consulta-protocolo/"
          onClick={collapseMenu}
        >
          CONSULTAR PROTOCOLO/CERTIDÃO
        </Link>
        <Link
          className="dropdown-item"
          to="/modelos-requerimentos/"
          onClick={collapseMenu}
        >
          MODELOS DE REQUERIMENTOS
        </Link>
        <Link
          className="dropdown-item"
          to="https://registradores.onr.org.br/Acesso.aspx"
          target="_blank"
          onClick={collapseMenu}
        >
          PEDIDO DE CERTIDÃO ONLINE
        </Link>
      </div>

      <Link className="nav-link">TÍTULOS, DOCUMENTOS E PJ</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="/por-que-registrar/"
          onClick={collapseMenu}
        >
          POR QUE REGISTRA?
        </Link>
        <Link
          className="dropdown-item"
          to="/modelos-requerimentos-pj/"
          onClick={collapseMenu}
        >
          MODELOS DE REQUERIMENTOS
        </Link>
        <Link
          className="dropdown-item"
          to="/o-que-registrar-rtd/"
          onClick={collapseMenu}
        >
          REGISTRO DE TÍTULOS E DOCUMENTOS
        </Link>
        <Link
          className="dropdown-item"
          to="/o-que-registrar-pj/"
          onClick={collapseMenu}
        >
          REGISTRO DE PESSOA JURÍDICA
        </Link>
        <Link
          className="dropdown-item"
          to="/tipos-certidoes/"
          onClick={collapseMenu}
        >
          TIPOS DE CERTIDÕES
        </Link>
      </div>
      <Link className="nav-link" to="/consulta-selo/" onClick={collapseMenu}>
        CONSULTA DE SELO
      </Link>
      <Link className="nav-link ">OUTRAS INFORMAÇÕES</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="/pesquisa-satisfacao/"
          onClick={collapseMenu}
        >
          PESQUISA DE SATISFAÇÃO
        </Link>
        <Link
          className="dropdown-item"
          to="/custas-e-emolumentos/"
          onClick={collapseMenu}
        >
          CUSTAS E EMOLUMENTOS
        </Link>
        <Link
          className="dropdown-item"
          to="/prazos-legais/"
          onClick={collapseMenu}
        >
          PRAZOS LEGAIS
        </Link>
        <Link
          className="dropdown-item"
          to="/o-que-e-uma-certidao/"
          onClick={collapseMenu}
        >
          O QUE É CERTIDÃO?
        </Link>
      </div>
      <Link
        className="nav-link"
        to="https://iuryflores.com.br/riscgo/Poli%CC%81tica%20de%20Privacidade-%20Final.pdf"
        target="_blank"
      >
        POLÍTICA DE PRIVACIDADE
      </Link>
      <Link className="nav-link" to="/solicitacao-dados/">
        SOLICITAÇÃO DE DADOS
      </Link>
      <Link className="nav-link" to="/ouvidoria/" onClick={collapseMenu}>
       OUVIDORIA
      </Link>
    </ul>
  );
};
